<template>
  <div :style="rootElementStyle" class="wh-space-bottom">

    <b-card v-show="showStat">
      <slot name="stat"></slot>
    </b-card>

    <b-card v-show="showSearch">
      <slot name="search"></slot>
    </b-card>

    <b-card>
      <slot></slot>
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard
} from 'bootstrap-vue'

export default {
  name: "PageContent",
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    showSearch: {type: Boolean, default: false},
    showStat: {type: Boolean, default: false},
    rootElementStyle: {type: String, default: ''}
  }
}
</script>

<style scoped>

</style>
