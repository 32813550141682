<template>
  <div>
    <v-select
      label="text"
      :options="options"
      v-model="rowData.facilityID"
      :reduce="text => text.value"
      :clearable="false"
      :searchable="false"
      :disabled="isEdit || disabled"
    >
      <template #no-options>
        {{$t('common.no_options')}}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from "@/common";

export default {
  name: "SlotFacility",
  components: {
    vSelect
  },
  props: {
    rowData: {type: Object},
    companyId: {type: Number},
    facilityId: {type: Number},
    isEdit: {type: Boolean},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    companyId: function (companyID, oldCompanyID) {
      if (oldCompanyID) {
        this.rowData.facilityID = null
        this.options = []
      }

      common.setAdminCompanyId(companyID)
      common.setAdminFacilityId(null)

      this.getFacilityOptions(companyID)
    },
    facilityId: function (facilityID) {
      common.setAdminFacilityId(facilityID)
    }
  },
  mounted() {
    if (common.getAdminCompanyId()) {
      this.getFacilityOptions(common.getAdminCompanyId())
    }
  },
  methods: {
    getFacilityOptions: function (company_id) {
      common.getFacilityOptions(company_id).then(res => {
        this.options = res
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
